@import "h5bp-normalize";
//@import "bootstrap/scss/bootstrap";
 @import "bootstrap/scss/_functions";
 @import "bootstrap/scss/_variables";
 @import "bootstrap/scss/_maps";
 @import "bootstrap/scss/_mixins";
 @import "bootstrap/scss/_utilities";
 @import "bootstrap/scss/_root";
 @import "bootstrap/scss/_reboot";
 @import "bootstrap/scss/_type";
 @import "bootstrap/scss/_images";
 @import "bootstrap/scss/_containers";
 @import "bootstrap/scss/_grid";
 @import "bootstrap/scss/_buttons";
 @import "bootstrap/scss/_transitions";
 @import "bootstrap/scss/_dropdown";
 @import "bootstrap/scss/_nav";
 @import "bootstrap/scss/_navbar";
 @import "bootstrap/scss/_card";
 @import "bootstrap/scss/_helpers";
 @import "bootstrap/scss/utilities/_api";
$standard-fontfamily: "open-sans-v18-latin-300", sans-serif;
$fontfamily2: "arbutus-slab-v9-latin-regular", serif;
$colorlila: #ececfe;
$coloryellow: #FCF7BD;
$colorblue: #00058E;
$colordark: #FFCB3E;
$colorlight: #FFFFFC;
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans-v18-latin-regular.eot");
  /* IE9 Compat Modes */
  src:local(''), url('../fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('../fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('../fonts/open-sans-v18-latin-regular.woff') format('woff'),
  /* Modern Browsers */
  url('../fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url( '../fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/open-sans-v18-latin-300.eot");
  /* IE9 Compat Modes */
  src:local(''), url('../fonts/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('../fonts/open-sans-v18-latin-300.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('../fonts/open-sans-v18-latin-300.woff') format('woff'),
  /* Modern Browsers */
  url('../fonts/open-sans-v18-latin-300.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url( '../fonts/open-sans-v18-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans-v18-latin-600.eot");
  /* IE9 Compat Modes */
  src:local(''), url('../fonts/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('../fonts/open-sans-v18-latin-600.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('../fonts/open-sans-v18-latin-600.woff') format('woff'),
  /* Modern Browsers */
  url('../fonts/open-sans-v18-latin-600.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url( '../fonts/open-sans-v18-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/open-sans-v18-latin-800.eot");
  /* IE9 Compat Modes */
  src:local(''), url('../fonts/open-sans-v18-latin-800.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('../fonts/open-sans-v18-latin-800.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('../fonts/open-sans-v18-latin-800.woff') format('woff'),
  /* Modern Browsers */
  url('../fonts/open-sans-v18-latin-800.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url( '../fonts/open-sans-v18-latin-800.svg#OpenSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}
/* arbutus-slab-regular - latin */
@font-face {
  font-family: 'Arbutus Slab';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/arbutus-slab-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src:local(''), url('../fonts/arbutus-slab-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('../fonts/arbutus-slab-v9-latin-regular.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('../fonts/arbutus-slab-v9-latin-regular.woff') format('woff'),
  /* Modern Browsers */
  url('../fonts/arbutus-slab-v9-latin-regular.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url( '../fonts/arbutus-slab-v9-latin-regular.svg#ArbutusSlab') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

body {
  background-color: $colorlila;
}

.container-fluid {
  background-color: $coloryellow;
}

.display-4 {
  text-align: center;
  font-family: "Arbutus Slab", serif;
  letter-spacing: 7px;
  color: $colorblue;
  padding-top: 8rem;
  padding-bottom: 4rem;
  background-color: $coloryellow;

  &:hover {
    color: $colorlila;
    padding-top: 8rem;
    padding-bottom: 4rem;
    text-decoration: none;
  }

  a {
    text-decoration: none;
    color: $colorblue;
  }
}

.container-xl {
  background-color: $colorlila;
  margin-top: 1rem;
}

.my-3 {
  border: 0.75px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h2 {
  text-align: left;
  font-size: 44px;
  font-family: "Arbutus Slab", serif;
  letter-spacing: 3.5px;
  color: $colorblue;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 992px) {
  h2 {
    text-align: left;
    font-size: 29px;
    font-family: "Arbutus Slab", serif;
    letter-spacing: 4px;
    color: $colorblue;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

h3 {
  text-align: left;
  font-size: 34px;
  line-height: 1.4;
  font-family: "Arbutus Slab", serif;
  color: $colorblue;
  padding-top: 0.7rem;
}
@media only screen and (max-width: 992px) {
  h3 {
    text-align: left;
    font-size: 24px;
    line-height: 1.4;
    font-family: "Arbutus Slab", serif;
    color: $colorblue;
    padding-top: 0.7rem;
  }
}

strong {
  font-weight: 800;
}

.lead-kontakt {
  text-align: center;
}

p.lead {
  text-align: left;
  font-size: 20px;
  line-height: 1.6;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: $colorblue;
  padding-top: 1rem;
}
@media only screen and (max-width: 992px) {
  p.lead {
    text-align: left;
    font-size: 16px;
    line-height: 1.6;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    color: $colorblue;
    padding-top: 1rem;
  }
}

.btn {
  background-color: $colorblue;
  border: 2.8px solid $colorblue;
  border-radius: 7px;
  font-family: "OpenSans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: $colorlight;

  &:hover {
    background-color: $colordark;
    border: 2.8px solid $colordark;
    border-style: solid;
    border-radius: 7px;
    color: $colorblue;
  }
}

.nav-pills {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.nav-item {
  background-color: $coloryellow;
  border: 2.8px solid $coloryellow;
  border-style: solid;
  color: $colorblue;
  flex-direction: row;
  text-align: center;

  &:hover {
    background-color: $colorlila;
    border: 2.8px solid $colorlila;
    border-style: solid;
    color: $colorblue;
  }
}

#navbarKabarett {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

#navbarGitarre {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

#navbarIdee {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

#navbarÜbermich {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

#navbarLiederabo {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

#navbarFotogalerie {
  padding-right: 3rem;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

.dropdown-menu {
  background-color: $colorlight;
}
@media only screen and (min-width: 480px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

.dropdown-item {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;

  &:hover {
    background-color: $colorlila;
    color: $colorblue;
  }
}

.zitat {
  text-align: left;
  font-size: 20px;
  line-height: 1.4;
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  color: $colorblue;
  padding-top: 0.7rem;
}
@media only screen and (max-width: 992px) {
  .zitat {
    text-align: left;
    font-size: 16px;
    line-height: 1.4;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    color: $colorblue;
    padding-top: 0.7rem;
  }
}

.card-audio {
  height: 400px;
  overflow: scroll;
  width: auto;
  background-color: $colorlight;
  margin-bottom: 1.5rem;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 1em;
    background-color: $colorlight;
    color: $colorblue;
  }

  &::-webkit-scrollbar-track {
    background: $coloryellow;
    box-shadow: $colorblue;
  }

  &::-webkit-scrollbar-thumb {
    background: $colorblue;
    height: 4rem;
  }
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $coloryellow;
}

.card-title {
  text-align: left;
  line-height: 1.4;
  font-size: 26px;
  font-family: "Arbutus Slab", serif;
  color: $colorblue;
}
@media only screen and (max-width: 992px) {
  .card-title {
    text-align: left;
    line-height: 1.4;
    font-size: 20px;
    font-family: "Arbutus Slab", serif;
    color: $colorblue;
  }
}

.card {
  margin-bottom: 1.5rem;
}

.card-referenzen {
  margin-bottom: 0;
}

.card-title-termine {
  text-align: left;
  line-height: 1.4;
  font-size: 26px;
  font-family: "Arbutus Slab", serif;
  color: $colorblue;
}
@media only screen and (max-width: 992px) {
  .card-title-termine {
    font-size: 20px;
  }
}

.card-subtitle-termine {
  text-align: left;
  font-size: 26px;
  font-family: "Arbutus Slab", serif;
  color: $colorblue;
  line-height: 1.4;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 992px) {
  .card-subtitle-termine {
    text-align: left;
    font-size: 20px;
    font-family: "Arbutus Slab", serif;
    color: $colorblue;
    line-height: 1.4;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.card-text {
  text-align: left;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  color: $colorblue;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 992px) {
  .card-text {
    text-align: left;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    color: $colorblue;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.youtube {
  position: relative;
  display: inline-block;
}

.youtube:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: transparent url("/img/yt_icon.png") center center no-repeat;
  background-size: 30%;
}

.foot {
  text-align: left;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}
@media only screen and (max-width: 992px) {
  .foot {
    text-align: left;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: $colorblue;
  }
}

.carousel-indicators {
  color: $colorblue;
}

.presse {
  padding-bottom: 2rem;
}

.card-title-presse {
  text-align: left;
  font-size: 26px;
  line-height: 2rem;
  font-family: "Arbutus Slab", serif;
  color: $colorblue;
}
@media only screen and (max-width: 992px) {
  .card-title-presse {
    text-align: left;
    font-size: 20px;
    line-height: 2rem;
    font-family: "Arbutus Slab", serif;
    color: $colorblue;
  }
}

p {
  text-align: left;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: $colorblue;
}

footer {
  width: 100%;
}

.container-fluid-footer {
  background-color: $colorblue;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.nav-item.footer {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  align-items: center;
  color: $colorlight;
  background-color: $colorblue;
  border-style: none;
  position: relative;
}

.nav-link {
  color: $colorlight;
  background-color: $colorblue;

  &:hover {
    color: $colordark;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $colorlight;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba0 5 142 1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.grid {
  .card-text {
    min-width: 380px;
  }
}
